import { ExperimentCountry, ExperimentName } from './types';

export const FEATURE_GATES = {
  homeDeliveryDeactivation: 'home_delivery_deactivation',
  priceReduction: 'price_reduction',
  noSignUpFee: 'no_sign_up_fee',
  sameDayPickup: 'same_day_pickup',
  signUpFeeReduction: 'sign_up_fee_reduction',
} as const;

export const DYNAMIC_CONFIG_NAMES = {
  formerSignUpFee: 'former_sign_up_fee',
  heavyUseRestriction: 'heavy_use_restriction',
  nextDayDeliveryAvailability: 'next_day_delivery_availability',
  storeLocations: 'store_locations',
  threeTierPricingProductsConfig: '3_tier_pricing_products_config',
  unavailableBikeHeightRange: 'unavailable_bike_height_range',
  zeroPrepaymentSubscriptionTypes: 'zero_prepayment_subscription_types',
} as const;

export const EXPERIMENTS: {
  [key in ExperimentCountry]?: Partial<Record<ExperimentName, string>>;
} = {
  ALL: {
    homepageStorytelling: 'homepage_storytelling',
    priceBreakdown: 'price_breakdown',
    configurePageMobileView: 'configure_page_mobile_view',
    framerHomepage: 'framer_homepage',
  },
  NL: {
    signUpFeeReduction: 'sign_up_fee_reduction_nl',
    ebikesZeroPrepayment: 'ebikes_zero_prepayment',
  },
  DE: {
    ebikesZeroPrepayment: 'ebikes_zero_prepayment',
  },
  DK: {
    ebikesZeroPrepayment: 'ebikes_zero_prepayment',
  },
};
